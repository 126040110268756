import * as React from 'react';
import { FC, useContext, useEffect, useState } from 'react';
import styled, { withTheme } from 'styled-components';
import { PacmanLoader } from 'react-spinners';
import { AppTheme } from '../../../theme';
import NotificationComponent from './NotificationComponent';
import { NotificationTypes } from '../../../models/basics/NotificationTypes';
import { GighubNotification } from '../../../models/gighubNotification';
import { EventController } from '../../../controllers/eventController';
import { toast } from 'react-toastify';
import Page from '../../_common/page/Page';
import { AvailabilityStati } from '../../../models/basics/AvailabilityStati';
import { BandController } from '../../../controllers/bandController';
import { RootStoreContext } from 'src/store/RootStore';
import { observer } from 'mobx-react-lite';
import { RouteComponentProps } from '@reach/router';
import { NotificationController } from "../../../controllers/notificationController";
import { compareDesc, compareAsc, parseISO } from "date-fns";
import { gighubNotificationTexts } from 'src/models/basics/gighubNotificationTexts';

interface Props {
    theme: AppTheme;
    path: string;
}

const Container = styled.div`
  overflow-y: auto;
  margin-top: 1rem;
  max-height: 80vh;
  padding: 0 1rem;
`;

const NoNotifications = styled.div`
  background: ${props => props.theme.colors.white};
  border-radius: 10px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  display: grid;
  place-items: center;
  color: ${props => props.theme.colors.black};
  padding: 2rem 0.8rem;
`;

const Notifications: FC<Props & RouteComponentProps> = observer(({ theme, navigate }) => {
    const {
        notificationsStore: { observables: notifications },
        eventsStore: { observables: events },
        bandsStore: { getById },
        userStore: { getById: getUserById },
    } = useContext(RootStoreContext);
    const [idBand, setIdBand] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        const pathname = window.location.href;
        localStorage.setItem('pageUrl', JSON.stringify(pathname));

        // Cleanup function to mark notifications as seen on component unmount
        return () => {
            markAllAsSeen();
        };
    }, []);

    async function markAllAsSeen() {
        const unseenNotifications = notifications.filter(notification => !notification.isSeen);
        const notificationController = new NotificationController();

        for (const notification of unseenNotifications) {
            if (!(!notification.isAnswered &&
                (notification.type === NotificationTypes.BANDINVITATION ||
                    notification.type === NotificationTypes.EVENTINVITATION))) {
                await notificationController.markAsSeen(notification);
            }

        }
    }

    async function handleInviteAccept(notification: GighubNotification) {
        if (notification && notification.id && notification.idReference) {
            if (notification.type === NotificationTypes.BANDINVITATION) {
                await new BandController().swapInviteToMember(notification.idReference, notification.id).then(async () => {
                    await new NotificationController().markAsSeen(notification)
                    toast.success('Hooray! You have joined a new Band');
                }).catch(async (error) => { console.log(error) });
            } else if (notification.type === NotificationTypes.EVENTINVITATION) {
                await new EventController(notification.idBand).handleInvite(notification, AvailabilityStati.ACCEPTED);
                toast.success('Yey! A new gig');
            }
        }
    }

    async function handleInviteDecline(notification: GighubNotification) {
        if (notification && notification.id && notification.idReference) {
            if (notification.type === NotificationTypes.EVENTINVITATION) {
                await new EventController(notification.idBand).handleInvite(notification, AvailabilityStati.DECLINED);
                await new NotificationController().markAsSeen(notification)
            }
        }
        toast.success("Ok! :'(");
    }

    function renderNotificationsNew() {
        if (loading) {
            return <PacmanLoader size={40} color={theme.colors.primary} loading={loading} />;
        } else {
            if (notifications.length > 0) {
                // Split notifications into "new" and "seen"
                const newNotifications = notifications
                    .filter(notification =>!notification.isSeen)
                    .sort((a, b) => compareAsc(
                        b.dateCreated instanceof Date ? b.dateCreated : parseISO(b.dateCreated),
                        a.dateCreated instanceof Date ? a.dateCreated : parseISO(a.dateCreated)
                    ));

                const seenNotifications = notifications
                    .filter(notification => notification.isSeen)
                    .sort((a, b) => compareAsc(
                        b.dateCreated instanceof Date ? b.dateCreated : parseISO(b.dateCreated),
                        a.dateCreated instanceof Date ? a.dateCreated : parseISO(a.dateCreated)
                    ));

                return (
                    <>
                        <h2>New Notifications</h2>
                        <br />
                        {newNotifications.length > 0 ? (
                            newNotifications.map(notification => (
                                <NotificationComponent
                                    key={notification.id}
                                    notification={notification}
                                    bandInviteModalOpen={idBand === notification.idReference && notification.type === NotificationTypes.BANDINVITATION}
                                    handleInviteAccept={handleInviteAccept}
                                    handleInviteDecline={handleInviteDecline}
                                    imageUrl={getBandImageUrl(notification) || ""}
                                    event={notification.type === NotificationTypes.EVENTINVITATION ? events.find(e => e.id === notification.idReference) || null : null}
                                />
                            ))
                        ) : (
                            <NoNotifications>No new notifications</NoNotifications>
                        )}
                        <br />
                        <hr />
                        <br />
                        <h2>Seen Notifications</h2>
                        <br />
                        {seenNotifications.length > 0 ? (
                            seenNotifications.map(notification => (
                                <NotificationComponent
                                    key={notification.id}
                                    notification={notification}
                                    bandInviteModalOpen={idBand === notification.idReference && notification.type === NotificationTypes.BANDINVITATION}
                                    handleInviteAccept={handleInviteAccept}
                                    handleInviteDecline={handleInviteDecline}
                                    imageUrl={getBandImageUrl(notification) || ""}
                                    event={notification.type === NotificationTypes.EVENTINVITATION ? events.find(e => e.id === notification.idReference) || null : null}
                                />
                            ))
                        ) : (
                            <NoNotifications>No seen notifications</NoNotifications>
                        )}
                    </>
                );
            } else {
                return <NoNotifications>You have no new notifications</NoNotifications>;
            }
        }
    }

    function getBandImageUrl(notification: GighubNotification) {
        const band = getById(notification.idBand).get();
        if (notification.type === NotificationTypes.YOUJOINEDBAND || notification.type === NotificationTypes.BANDRELATED) {
            if (band && band.imageUrl) {
                return band.imageUrl
            }
        } else {
            if (band && band.members && band.members[notification.idSender]) {
                return band.members[notification.idSender].imageUrl
            }
        }
        return null
    }


    return (
        <>
            {navigate && (
                <Page navigate={navigate}>
                    <Container>{renderNotificationsNew()}</Container>
                </Page>
            )}
        </>
    );
});

export default withTheme(Notifications);

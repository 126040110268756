import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { Customer } from 'src/models/customer';
import { GuestlistEntry } from 'src/models/basics/GuestlistEntry';
import { Dialog, DialogActions } from '@material-ui/core';
import { GighubEvent } from 'src/models/gighubEvent';
import { EventController } from 'src/controllers/eventController';
import { auth } from 'src/database/fbApp';
import { toast } from 'react-toastify';
import { FileCopy } from '@material-ui/icons';
import { BlueButton } from 'src/views/_common/_elements';

interface Props {
  customer: Customer | null;
  guestlist: GuestlistEntry[];
  event: GighubEvent;
  setEvent: (event: GighubEvent) => void;
}

const Container = styled.div`
  padding: 1.5rem;
`;

const Title = styled.h3`
  margin: 2rem 0;
  font-weight: bold;
`;

const TextContainer = styled.div`
  strong {
    font-weight: bold;
  }

`;

const GuestListEntryContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  grid-column-gap: 1rem;
  padding: 1rem;

  &:first-of-type {
    margin-top: 1rem;
  }
`;

const Button = styled.button<{ color?: string }>`
  background-color: ${props => props.color || '#4CAF50'};
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const CopyToClipboardButton = styled.button`
  color: black;
  padding: 0.5rem 1rem;
  border: 1px solid black;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 3rem;
`;

const DialogContainer = styled.div`
  padding: 2rem;
`;

const DialogForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const DialogInput = styled.input`
  margin: 1rem 0;
  padding: 0.5rem;
  border: none;
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
`;

const DialogButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;

const DialogButton = styled.button`
  background-color: #4CAF50;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    transform: scale(1.1);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.2);
  }
`;

const Guestlist: FC<Props> = ({ customer, guestlist, event, setEvent }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [newEntry, setNewEntry] = useState<GuestlistEntry>({
    idUser: "",
    entry: "",
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setNewEntry({ ...newEntry, [name]: value });
  };

  const handleSubmit = async (entry: GuestlistEntry) => {
    setShowPopup(false);
    if (!event.guestlist) { event.guestlist = [] }
    const currentUser = (window as any).gighub.CurrentUser;
    if (auth.currentUser && auth.currentUser) {
      entry.idUser = auth.currentUser.uid
      entry.entry = currentUser.fullName + ": " + entry.entry
      event.guestlist.push(entry)
      try {
        await new EventController(event.idBand).update(event.id ? event.id : "", event);
      } catch (e) {
        console.log(e);
      }
    }

    // Reset the newEntry state
    setNewEntry({
      idUser: "",
      entry: "",
    });
  }

  const handleDelete = async (index: number) => {
    try {
      if (event && event.id && event.guestlist && event.guestlist[index]) {
        const confirmed = window.confirm("Are you sure you want to delete this entry?");
        if (confirmed && event.guestlist[index].idUser === (auth.currentUser ? auth.currentUser.uid : "")) {
          
          // Create a shallow copy of the guestlist
          const updatedGuestlist = [...event.guestlist];
          updatedGuestlist.splice(index, 1);
          
          // Immediately update the event state
          setEvent({ ...event, guestlist: updatedGuestlist });
  
          // Save to the database
          await new EventController(event.idBand).update(event.id, { ...event, guestlist: updatedGuestlist });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };
  

  const [clipboardText, setClipboardText] = useState('');

  const handleExportToClipboard = () => {
    const exportedText = guestlist.map((entry) => {
      const parts = entry.entry.split(': ');
      if (parts.length === 2) {
        return "- " + parts[1];
      }
      return "- " + entry.entry;
    }).join('\n');
    setClipboardText(exportedText);
    // Copy the text to the clipboard
    if (navigator.clipboard) {
      navigator.clipboard.writeText(exportedText).then(() => {
        toast('Guestlist copied to clipboard!');
      });
    }
  };

  return (
    <Container>
      <Title>Guestlist <CopyToClipboardButton onClick={handleExportToClipboard}><FileCopy/></CopyToClipboardButton></Title>
      <TextContainer>

        <Dialog open={showPopup}>
          <DialogContainer>
            <h3>Add Guest Entry</h3>
            <DialogForm onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(newEntry);
            }}>
              <DialogInput
                type="textarea"
                name="entry"
                value={newEntry.entry}
                onChange={handleInputChange}
                placeholder="Enter guest name"
              />
              <DialogButtonContainer>
                <DialogButton type="submit">Add</DialogButton>
              </DialogButtonContainer>
            </DialogForm>
          </DialogContainer>
        </Dialog>
        {guestlist.map((entry, index) => (
          <GuestListEntryContainer key={index}>
            <div>{entry.entry}</div>

            {entry.idUser === (auth.currentUser ? auth.currentUser.uid : "") && <div>
              <Button color="#f16e57" onClick={() => handleDelete(index)}>Delete</Button>
            </div>}
          </GuestListEntryContainer>
        ))}
        <br />
        <BlueButton onClick={() => setShowPopup(true)}>Add Entry</BlueButton>
      </TextContainer>
    </Container>

  )
}

export default Guestlist;
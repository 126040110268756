import * as React from 'react';
import * as ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import registerServiceWorker from './registerServiceWorker';
import { ThemeProvider } from 'styled-components';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import { blue, deepOrange } from '@material-ui/core/colors';
import { theme } from './theme';
import ErrorBoundary from './views/ErrorBoundary';
import * as Sentry from '@sentry/browser';
import LogRocket from 'logrocket';

LogRocket.init('u1doh0/gighub');
Sentry.init({ dsn: "https://b07118996b1e489cb4b7f6d1a086ba88@sentry.io/1795155" });
LogRocket.getSessionURL(sessionURL => {
    Sentry.configureScope(scope => {
        scope.setExtra("sessionURL", sessionURL);
    });
});
const muiTheme = createTheme(
    {
        typography: {
            fontSize: 24
        },
        palette: {
            primary: blue,
            secondary: deepOrange
        }
    }
)


ReactDOM.render(
    <ErrorBoundary>
        <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <MuiThemeProvider theme={muiTheme}>
                    <App />
                </MuiThemeProvider>
            </MuiPickersUtilsProvider>
        </ThemeProvider>
    </ErrorBoundary>,
    document.getElementById('root') as HTMLElement
);
registerServiceWorker();

import React, { FC, useState, useEffect, useContext, useLayoutEffect } from 'react';
import BandSettingsForm from './BandSettingsForm';
import SectionTitle from '../../../pages/_elements/SectionTitle';
import { Band } from '../../../../models/band';
import { BandController } from '../../../../controllers/bandController';
import SettingsContainer from '../../_elements/SettingsContainer';
import { toast } from 'react-toastify';
import { navigate, RouteComponentProps } from '@reach/router';
import { Routes } from '../../../../constants/Routes';
import CustomersSettings from './CustomerSettings/CustomersSettings';
import FormationSettings from './FormationSettings/FormationSettings';
import styled from 'styled-components';
import { RootStoreContext } from 'src/store/RootStore';
import { observer } from 'mobx-react-lite';
import { autorun } from 'mobx';
import SettingsTopNav from '../../_elements/SettingsTopNav';
import { UsersBandsController } from '../../../../controllers/usersBandsController';
import { UsersBands } from '../../../../models/users-bands';
import { auth } from '../../../../database/fbApp';
import { isAdmin, MemberRoles } from 'src/models/basics/MemberRoles';
import AdminSection from './AdminSection';
import MemberSettingsPage from "./memberSettings/MemberSettingsPage";
import Billing from "./BillingSettings/Billing";
import { Member } from "../../../../models/basics/member";
import BandAddressSettings from './BandAddressSettings/BandAddressSettings';
import BookingStatusSettings from './BookingStatiSettings/BookingStatusSettings';

interface Props {
    path: string;
    id?: string;
}

const Content = styled.div`
  width: 100%;
  margin: 0 auto;
`;

const TabNavigation = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: ${props => props.theme.colors.white};
`;

const Tab = styled('a') <{ active: boolean }>`
  padding: 1rem;
  display: block;
  width: 100%;
  text-align: center;
  border-bottom: ${props => (props.active ? `1px solid ${props.theme.colors.white}` : 'none')};
  transition: all 0.25s;
`;

const BandSettingsPage: FC<Props & RouteComponentProps<{ id: string, tab: string }>> = observer(props => {
    const [band, setBand] = useState<Band | null>(null);
    const {
        bandsStore: { observables: bands, getById: getBandById, setSelectedBand },
        userStore: { currentUser },
        bandAddressesStore,
        bookingStatiStore,
        customersStore,
        formationsStore,
        billingInfosStore,
    } = useContext(RootStoreContext);

    const [activeTab, setActiveTab] = useState(0);



    useEffect(() => {
        if (props.tab) {
            setActiveTab(Number(props.tab))
        }
    }, [])

    useEffect(() => {
        autorun(() => {
            if (props.id) {
                const urlBand = getBandById(props.id).get();
                if (urlBand) {
                    setBand(urlBand);
                }
            }
        });
    });

    async function handleSubmit(name: string, shorthand: string, primaryColor: string, currency: string, costPerKilometer: number, showDrivingExpenses: boolean) {
        if (band) {
            const { id, subscriptionType, canceledSubscription } = band;
            if (id) {
                try {
                    await new BandController().update<Band>(id, new Band({
                        id: '',
                        name,
                        shorthand,
                        primaryColor,
                        subscriptionType,
                        canceledSubscription,
                        members: {},
                        currency,
                        costPerKilometer,
                        showDrivingExpenses
                    }));
                    toast.success('updated band');
                } catch (e) {
                    console.log(e);
                }
            }
        } else {
            try {
                const newBand = true;
                const { id: newId } = await new BandController().create(
                    new Band({
                        id: '',
                        name,
                        shorthand,
                        primaryColor,
                        subscriptionType: 'free',
                        canceledSubscription: false,
                        members: {},
                        currency,
                        costPerKilometer,
                        showDrivingExpenses
                    }, newBand)
                );
                await new UsersBandsController().create(
                    new UsersBands({ idBand: newId, idUser: auth.currentUser ? auth.currentUser.uid : '' })
                );
                await setSelectedBand(newId);
                toast.success("You have created a new band! Now let's add some members");
                // Navigate to "Members"
                await navigate(`/bands/${newId}/1`);
                window.location.reload();

            } catch (e) {
                console.log(e);
            }
        }
    }

    function renderContent() {
        if (band && currentUser) {
            switch (activeTab) {
                case 0:
                    return (
                        <>
                            <BandSettingsForm handleSubmit={handleSubmit} band={band} />
                            {isAdmin(band.members[currentUser.id!].role) && <AdminSection band={band} />}
                        </>
                    );
                case 1:
                    return <MemberSettingsPage band={band} authRole={band.members[currentUser.id!].role} />;
                case 2:
                    return <CustomersSettings idBand={band.id} customers={customersStore.getByBand(band.id).get()} />;
                case 3:
                    return <FormationSettings band={band} formations={formationsStore.getByBand(band.id).get()} />;
                case 4: {
                    const billingInfos = billingInfosStore.getByBand(band.id).get();
                    return <Billing band={band} billingInfos={billingInfos[0]} />;
                }
                case 5:
                    return <BandAddressSettings idBand={band.id} bandAddresses={bandAddressesStore.getByBand(band.id).get()} />;
                case 6:
                    return <BookingStatusSettings idBand={band.id} bookingStati={bookingStatiStore.getByBand(band.id).get()} />;
            }
        }

        return null;
    }

    function renderAccordion() {
        if (!band && currentUser) {
            return <BandSettingsForm handleSubmit={handleSubmit} band={null} />;
        } else {
            return (
                <Content>
                    <TabNavigation>
                        <Tab onClick={() => setActiveTab(0)} active={activeTab === 0}>
                            Band
                        </Tab>
                        <Tab onClick={() => setActiveTab(1)} active={activeTab === 1}>
                            Members
                        </Tab>
                        <Tab onClick={() => setActiveTab(2)} active={activeTab === 2}>
                            Customers
                        </Tab>
                        <Tab onClick={() => setActiveTab(3)} active={activeTab === 3}>
                            Formations
                        </Tab>
                        {/* <Tab onClick={() => setActiveTab(4)} active={activeTab === 4}>
                            Billing
            </Tab>*/}
                        <Tab onClick={() => setActiveTab(5)} active={activeTab === 5}>
                            Addresses
                        </Tab>
                        <Tab onClick={() => setActiveTab(6)} active={activeTab === 6}>
                            Booking
                        </Tab>
                    </TabNavigation>
                    {renderContent()}
                </Content>
            );
        }
    }

    return (
        <SettingsContainer>
            <div style={{ width: '100%', margin: '0 auto', maxWidth: '1200px' }}>
                <SettingsTopNav clickHandler={() => navigate(Routes.BANDS)} />
                {renderAccordion()}
            </div>
        </SettingsContainer>
    );
});

export default BandSettingsPage;

import React, { FC, useState } from 'react';
import styled from 'styled-components';
import PricingCard from 'src/views/_common/_elements/PricingCard';
import { DangerButton } from 'src/views/_common/_elements';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField } from '@material-ui/core';
import { Band } from 'src/models/band';
import { toast } from 'react-toastify';
import { BandController } from 'src/controllers/bandController';  // Adjust import as necessary
import { navigate } from '@reach/router';
import { Routes } from 'src/constants/Routes';
import { UsersBandsController } from 'src/controllers/usersBandsController';

interface Props {
  band: Band;
}

const Container = styled.div`
  /* Your styles here */
`;

const AdminSection: FC<Props> = ({ band }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [confirmationText, setConfirmationText] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);

  const requiredConfirmation = "i want to delete this band";

  const handleDeleteClick = async () => {
    try{
        await new UsersBandsController().cleanUp;
    } catch (e){
        console.log(e);
    }

    setIsModalOpen(true);
  };

  const handleClose = () => {
    setIsModalOpen(false);
    setConfirmationText('');
  };

  const handleConfirmDelete = async () => {
    if (confirmationText === requiredConfirmation) {
      setIsDeleting(true);
      try {
        await new BandController().delete(band.id);
        toast.success("Band successfully deleted.");
        handleClose();
        navigate(Routes.HOME);
      } catch (error) {
        toast.error("An error occurred while deleting the band.");
        setIsDeleting(false);
      }
    } else {
      toast.error("The confirmation text does not match.");
    }
  };

  return (
    <Container>
      <PricingCard band={band} />
      {band && (
        <div>
          <br /><br />
          <h1>Danger Zone</h1>
          <DangerButton className='btn' type="submit" onClick={handleDeleteClick}>
            Delete Band
          </DangerButton>
        </div>
      )}

      {/* Delete Confirmation Dialog */}
      <Dialog open={isModalOpen} onClose={handleClose}>
        <DialogTitle>Confirm Band Deletion</DialogTitle>
        <DialogContent>
          <p>
            To confirm the deletion of this band, please type the following text:
            <strong> "{requiredConfirmation}"</strong>
          </p>
          <TextField
            autoFocus
            fullWidth
            margin="dense"
            label="Confirmation"
            value={confirmationText}
            onChange={(e) => setConfirmationText(e.target.value)}
            placeholder={requiredConfirmation}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary" disabled={isDeleting}>
            Cancel
          </Button>
          <Button
            onClick={handleConfirmDelete}
            color="primary"
            disabled={isDeleting}
          >
            {isDeleting ? "Deleting..." : "Delete Band"}
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default AdminSection;

import { Controller } from "./controller";
import { Collections } from "../models/basics/Collections";
import { collection, getDocs, query, where, writeBatch } from "firebase/firestore";
import { BandController } from "./bandController";
import { UsersBands } from "src/models/users-bands";
import { auth, database as db } from "../database/fbApp";


export class UsersBandsController extends Controller {
    constructor() {
        super(Collections.USERSBANDS);
    }

    async deleteConnection(idBand: string, idUser?: string) {
        try {
            let q = query(this.subscribe());
            if (idUser) {
                q = query(q, where("idUser", "==", idUser));
            }
            q = query(q, where("idBand", "==", idBand));
            const res = await getDocs(q);
            if (res && res.docs) {
                res.docs.forEach((doc) => {
                    this.delete(doc.id);
                });
            }
            return true;
        } catch (e) {
            console.log(e);
            return false;
        }
    }

    async cleanUp() {
        try {
            // Step 1: Get all usersbands entries
            const allUserBandsSnapshot = await this.getAllUserBands();
            // Step 2: Initialize BandController to check valid band IDs
            const bandController = new BandController();
            // Step 3: Create a batch for bulk operations
            const batch = writeBatch(db);
            let invalidEntries = 0;
            // Step 4: Iterate through each entry in usersbands
            for (const userBandDoc of allUserBandsSnapshot.docs) {
                const userBandData = userBandDoc.data() as UsersBands;

                // Step 5: Check if the band with idBand still exists
                const bandExists = userBandData.idBand ? await bandController.get(userBandData.idBand) : null;

                if (!bandExists) {
                    console.log("Band "+userBandData.idBand+ "does not exist. let's clean that up");
                    // Step 6: Delete the usersbands entry if the band doesn't exist
                    batch.delete(userBandDoc.ref);
                    invalidEntries++;
                }
            }

            // Step 7: Commit the batch deletion
            if (invalidEntries > 0) {
                await batch.commit();
                console.log(`${invalidEntries} invalid usersbands entries deleted.`);
            } else {
                console.log("No invalid usersbands entries found.");
            }
            console.log("Cleanup Done");
            return true;
        } catch (error) {
            console.error("Error during clean-up:", error);
            return false;
        }
    }

    async getAllUserBands() {
        // Helper method to get all usersbands documents
        return await getDocs(this.subscribeQ());
    }
}

export enum Collections {
    USERS = 'users',
    USERSBANDS = 'users-bands',
    BANDS = 'bands',
    EVENTS = 'events',
    CALENDAREVENTS = 'calendarEvents',
    CUSTOMERS = 'customers',
    NOTIFICATIONS = 'notifications',
    FORMATIONS = 'formations',
    BILLINGINFOS = 'billingInfos',
    BANDS_SUBSCRIPTIONS = 'bands-subscriptions',
    SECRET_TOKENS = 'secretTokens',
    MAIL = 'mail_neu',
    BANDADDRESSES = 'bandAddresses',
    BOOKINGSTATI = 'bookingStati'
}
import * as React from 'react';
import { FC, useContext, useState } from 'react';
import { Band } from '../../../../../models/band';
import { BandController } from '../../../../../controllers/bandController';
import { toast } from 'react-toastify';
import MemberWrapper from './MemberWrapper';
import styled from 'styled-components';
import { isAdmin, MemberRoles } from '../../../../../models/basics/MemberRoles';
import { Member } from '../../../../../models/basics/member';
import Fab from '../../../../_common/_elements/Fab';
import NewMemberForm from './NewMemberForm';
import { NotificationController } from '../../../../../controllers/notificationController';
import { NotificationTypes } from '../../../../../models/basics/NotificationTypes';
import { RootStoreContext } from 'src/store/RootStore';
import { observer } from 'mobx-react-lite';
import { UsersBandsController } from '../../../../../controllers/usersBandsController';
import { UsersBands } from '../../../../../models/users-bands';
import { useToggle } from 'src/hooks/useToggle';
import PaymentDialog from 'src/views/_common/_elements/PaymentDialog';
import { MAX_MEMBERS } from 'src/constants/FreePlan';
import { Dialog } from '@material-ui/core';
import { UserController } from 'src/controllers/userController';
import { User } from 'src/models/user';
import { Mail } from 'src/models/mail';
import { MailController } from 'src/controllers/mailController';
import { BlueButton } from 'src/views/_common/_elements';

interface Props {
    band: Band;
    authRole: MemberRoles;
    onNext?: () => void;
}

const MembersContainer = styled.div`
  padding: 20px;
`;

const Container = styled.div`
      margin-top: 2rem;
`;

const BlueButtonCustom = styled(BlueButton)`
  margin-top: 4rem;

`;

const MemberSettingsPage: FC<Props> = observer((props) => {
    const {
        userStore: { currentUser }
    } = useContext(RootStoreContext);
    const [band, setBand] = useState<Band | null>(props.band);
    const { on: memberFormOpen, toggle: toggleMemberForm } = useToggle();
    const { on: paymentFormOpen, toggle: togglePaymentForm } = useToggle();

    async function handleSubmit(role: MemberRoles, idUser?: string, email?: string) {

        if (band) {
            if (idUser) {
                try {
                    if (!band.members[idUser]) {
                        await new UsersBandsController().create(new UsersBands({ idUser, idBand: band.id }));
                    }
                    const userMember = await new UserController().get(idUser) as User;
                    band.members[idUser] = new Member({ role });
                    band.members[idUser].fullName = (userMember.surname || "") + " " + (userMember.name || "");
                    band.members[idUser].imageUrl = userMember.imageUrl || "";
                    await new BandController().update<Band>(band.id, band);
                    setBand(band);
                    await new NotificationController().notify(
                        { id: idUser } as Partial<User>,
                        "You are now " + role + " @ " + band.name,
                        NotificationTypes.YOUJOINEDBAND,
                        band.id,
                        band
                    );
                    toast.success("done!");
                } catch (e) {
                    console.log(e);
                }
            } else if (email) {
                try {
                    band.invites
                        ? (band.invites[email] = new Member({ role }))
                        : (band.invites = { [email]: new Member({ role }) });
                    await new BandController().update<Band>(band.id, band).then(async () => {
                        const subject = "Gighub: You have been invited to join '" + band.name;
                        const text = "You have been invited to join '" + band.name + "' as " + role;
                        const html = "<p>" + subject + "</p><br><br><a href='gighub.ch/signup/" + band.id + "/" + email + "'>Join " + band.name + "</a><br><br>Sincerely,<br>GigHub Team <3";
                        const mail = new Mail(email, { subject, text, html });
                        await new MailController().create(mail);
                        toast.success(email + " has been invited to join '" + band.name + "' as " + role);
                    })
                        .catch((e) => {
                            console.log("error updating band in membersettingsform", e)
                        });
                } catch (e) {
                    console.log(e);
                }
            } else {
                toast.error("Oops! Something went wrong");
            }
        }
    }

    async function handleDelete(idMember: string) {
        if (band && idMember && Object.keys(band.members).length > 1) {
            await new BandController().deleteMember(band, idMember);
        } else {
            toast.error('Oops! Something went wrong');
        }
    }

    async function handleDeleteInvite(email: string) {
        if (band && email) {
            try {
                delete band.invites[email];
                await new BandController().update<Band>(band.id, band);
                toast.success('Removed invite');
            } catch (e) {
                console.log(e);
            }
        }
    }

    function renderMembers() {
        let adminCount = 0;
        if (band) {
            if (Object.keys(band.members).length > 0) {
                Object.keys(band.members).map(idUser => {
                    if (band.members[idUser].role === MemberRoles.ADMIN) adminCount++
                }
                )
                return Object.keys(band.members).map(idUser => (
                    <MemberWrapper
                        key={idUser}
                        idUser={idUser}
                        member={band.members[idUser]}
                        handleSubmit={handleSubmit}
                        handleDelete={handleDelete}
                        authRole={props.authRole}
                        isTheOnlyAdmin={(band.members[idUser].role === MemberRoles.ADMIN && adminCount === 1)}
                    />
                ));
            }
        }
        return 'No Members found';
    }

    function renderInvites() {
        if (band) {
            if (band.invites !== undefined) {
                if (Object.keys(band.invites).length > 0) {
                    return Object.keys(band.invites).map(email => (
                        <MemberWrapper
                            key={email}
                            idUser={email}
                            member={{ fullName: email, role: band.invites[email].role }}
                            handleSubmit={handleSubmit}
                            handleDelete={handleDeleteInvite}
                            authRole={props.authRole}
                            invite={true}
                        />
                    ));
                }
            }
        }
        return '';
    }

    function handleAddMemberClick() {
        if (band) {
            if (band.subscriptionType === 'premium' || Object.keys(band.members).length < MAX_MEMBERS) {
                toggleMemberForm();
            } else {
                togglePaymentForm();
            }
        }
    }

    return (
        <Container>
            {props.onNext && <button onClick={props.onNext}>Next funnel step</button>}
            <>
                <MembersContainer>
                    {renderMembers()}
                    <hr />
                    {renderInvites()}
                    <BlueButtonCustom className='btn' onClick={handleAddMemberClick}>
                        {band && Object.keys(band.members).length === 1 ? 'Your band has no other members yet. Do you want to add them?' : 'Add Member'}
                    </BlueButtonCustom>
                </MembersContainer>
            </>
            <Dialog open={memberFormOpen} onClose={toggleMemberForm}>
                {isAdmin(props.authRole) && (
                    <NewMemberForm handleSubmit={handleSubmit} toggle={toggleMemberForm} authRole={props.authRole} />
                )}
            </Dialog>
            {currentUser && band && (
                <PaymentDialog on={paymentFormOpen} toggle={togglePaymentForm} currentUser={currentUser}
                    bandId={band.id} />
            )}
        </Container>
    );
})
    ;

export default MemberSettingsPage;

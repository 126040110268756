import React, { FC, useContext, useEffect, useState } from 'react';
import Page from '../../_common/page/Page';
import { Routes } from '../../../constants/Routes';
import EventWrapper from './EventWrapper';
import { Link, RouteComponentProps } from '@reach/router';
import Fab from '../../_common/_elements/Fab';
import styled, { withTheme } from 'styled-components';
import { RootStoreContext } from 'src/store/RootStore';
import { observer } from 'mobx-react-lite';
import ShowAllButton from '../_elements/ShowAllButton';
import { GighubEvent } from '../../../models/gighubEvent';
import Toggle from '../../_common/utilities/Toggle';
import { useToggle } from 'src/hooks/useToggle';
import PaymentDialog from 'src/views/_common/_elements/PaymentDialog';
import { isWithinInterval } from 'date-fns';
import { MAX_YEARLY_EVENTS } from 'src/constants/FreePlan';
import BandSelect from 'src/views/_common/_elements/BandSelect';
import useWindowSize from 'src/hooks/useWindowSize';
import { AppTheme } from 'src/theme';
import { canWriteBand, isAdminBand } from '../../../models/basics/MemberRoles';
import { EventStati } from 'src/models/basics/EventStati';
import { BandStati } from 'src/models/basics/BandStati';


interface Props {
    path: Routes;
    theme: AppTheme;
}

const Container = styled.div`
  margin-top: 1rem;
  padding: 0 1rem;
  height: 100%;
  width: 100%;
`;

const AddEventFab = styled(Fab)`
  && {
    bottom: calc(${props => props.theme.layout.footerHeight} + 1rem) !important;
  }
`;

const PastEventsContainer = styled.div`
   margin-top: 1rem;
`;

const FilterSection = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;

  > * {
    margin-right: 1rem;
  }

  label {
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
    margin-right: 0.5rem;
  }

  select {
    font-size: 1.2rem;
    border: none;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    background-color: #f2f2f2;
    color: #333;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: background-color 0.2s ease-in-out;
    appearance: none;

    &:hover {
      background-color: #e0e0e0;
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px #0070f3;
    }
  }
`;

const EventsPage: FC<Props & RouteComponentProps> = observer(({ navigate, theme }) => {
    const {
        bandsStore: { selectedBand, getById: getBandById, setSelectedBandFromLocalStorage },
        eventsStore: { getEventsByBand, getFutureEventsByBand, getFutureEventsParticipatingByBand, getPastEventsByBand, getPastEventsParticipatingByBand },
        formationsStore: { getById: getFormationById },
        userStore: { currentUser },
    } = useContext(RootStoreContext);
    const { on: paymentFormOpen, toggle: togglePaymentForm } = useToggle();
    const { width } = useWindowSize();

    useEffect(() => {
        const pathname = window.location.href;
        localStorage.setItem('pageUrl', JSON.stringify(pathname))
    }, []);

    const [statusFilter, setStatusFilter] = useState<'all' | EventStati | string>('all');
    const [bandStatusFilter, setBandStatusFilter] = useState<'all' | BandStati>('all');
    const [accommodationFilter, setAccommodationFilter] = useState<'all' | 'provided' | 'notProvided'>('all');

    function filterEvents(events: GighubEvent[]) {
        return events
            .filter(event => {
                if (statusFilter === 'all') return true;
                return statusFilter === event.status;
            })
            .filter(event => {
                if (bandStatusFilter === 'all') return true;

                return bandStatusFilter === event.bandStatus;
            })
            .filter(event => {
                if (accommodationFilter === 'all') return true;

                const hasAccommodation = event.accommodation;
                return accommodationFilter === 'provided' ? hasAccommodation : !hasAccommodation;
            });
    }

    function mapEvents(events: GighubEvent[]) {
        events = filterEvents(events)
        return events
            .map(event => (
                <EventWrapper
                    key={event.id}
                    event={event}
                    fullyDressed={true}
                    band={getBandById(event.idBand).get() || null}
                    formation={(event.idFormation && getFormationById(event.idFormation).get()) || null}
                />
            ));
    }

    function isAllowedToAddEvent() {
        if (!selectedBand) {
            return false;
        }
        const events = getEventsByBand(selectedBand.id).get();
        let eventCounter = 0;
        events.forEach(event => {
            const eventDate = event.dateCreated || new Date();
            const dateYesterday = new Date(new Date().setDate(new Date().getDate() - 1));
            const dateInOneYear = new Date(new Date().setFullYear(new Date().getFullYear() + 1));
            if (isWithinInterval(eventDate, { start: dateYesterday, end: dateInOneYear })) {
                eventCounter++;
            }
        });
        return selectedBand.subscriptionType === 'premium' || eventCounter < MAX_YEARLY_EVENTS;
    }

    useEffect(() => {
        if (!selectedBand) {
            setSelectedBandFromLocalStorage();
        }
    });

    return (
        <>
            {navigate && (
                <Page navigate={navigate}>
                    {selectedBand && (
                        <Container>
                            {/* Filter section */}
                            <FilterSection>
                                <div>
                                    <label htmlFor="status-filter">Status:</label>
                                    <select
                                        id="status-filter"
                                        value={statusFilter}
                                        onChange={e => setStatusFilter(e.target.value as typeof statusFilter)}
                                    >
                                        <option value="all">All</option>
                                        {Object.entries(EventStati).map(([key, value]) => (
                                            <option value={value} key={key}>{value}</option>
                                        ))}
                                    </select>
                                </div>
                                <div>
                                    <label htmlFor="band-status-filter">Band status:</label>
                                    <select
                                        id="band-status-filter"
                                        value={bandStatusFilter}
                                        onChange={e => setBandStatusFilter(e.target.value as typeof bandStatusFilter)}
                                    >
                                        <option value="all">All</option>
                                        {Object.entries(BandStati).map(([key, value]) => (
                                            <option value={value} key={key}>{value}</option>
                                        ))}
                                    </select>
                                </div>
                                <div>
                                    <label htmlFor="accomodation-filter">Accomodation:</label>
                                    <select
                                        id="accomodation-filter"
                                        value={accommodationFilter}
                                        onChange={e => setAccommodationFilter(e.target.value as typeof accommodationFilter)}
                                    >
                                        <option value="all">All</option>
                                        <option value="provided">provided</option>
                                        <option value="notProvided">not provided</option>
                                    </select>
                                </div>
                            </FilterSection>
                            {width && width > theme.breakpoints.desktop && <BandSelect />}
                            <Toggle>
                                {({ on, toggle }) => (
                                    <>
                                    <ShowAllButton
                                            active={false}
                                            text={on ? 'hide past events' : 'show past events'}
                                            clickHandler={() => {
                                                toggle();
                                            }}
                                        />
                                        {on && (
                                            <PastEventsContainer>
                                                {mapEvents(
                                                    canWriteBand(selectedBand) ?
                                                        getPastEventsByBand(selectedBand.id).get()
                                                        : getPastEventsParticipatingByBand(selectedBand.id).get()
                                                )}
                                            </PastEventsContainer>
                                        )}
                                    </>
                                )}
                            </Toggle>
                            {mapEvents(
                                canWriteBand(selectedBand) ?
                                    getFutureEventsByBand(selectedBand.id).get()
                                    : getFutureEventsParticipatingByBand(selectedBand.id).get()
                            )}
                            {selectedBand && canWriteBand(selectedBand) ? (
                                <div
                                    onClick={e => {
                                        if (!isAllowedToAddEvent()) {
                                            navigate(Routes.EVENTS);
                                            togglePaymentForm();
                                        }
                                    }}
                                >
                                    <Link to={Routes.EVENT_FORM_PAGE}>
                                        <AddEventFab type={'ADD'} footerMargin={true} />
                                    </Link>
                                </div>
                            ) : (
                                canWriteBand(selectedBand) && (
                                    <AddEventFab type={'ADD'} footerMargin={true} onClick={togglePaymentForm} />
                                )
                            )}
                            {currentUser && selectedBand && (
                                <PaymentDialog
                                    on={paymentFormOpen}
                                    toggle={togglePaymentForm}
                                    currentUser={currentUser}
                                    bandId={selectedBand.id}
                                />
                            )}
                        </Container>
                    )}
                </Page>
            )}
        </>
    );
});

export default withTheme(EventsPage);

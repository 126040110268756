import React, { FC, useContext, useEffect, useState } from 'react';
import { auth, database, initializePush, fbApp, database as db } from './database/fbApp';
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { Router } from '@reach/router';
import { Routes } from './constants/Routes';
import SignupPage from './views/auth/SignupPage/SignupPage';
import HubPage from './views/pages/hub/HubPage';
import CalendarPage from './views/pages/calendar/CalendarPage/CalendarPage';
import Bands from './views/settings/bands/Bands';
import LoginPage from './views/auth/LoginPage/LoginPage';
import ForgotPasswordPage from './views/auth/ForgotPasswordPage/ForgotPasswordPage';
import { User } from './models/user';
import { UserController } from './controllers/userController';
import AdditionalUserInformationPage from './views/auth/AdditionalUserInformationPage/AdditionalUserInformationPage';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import { RingLoader } from 'react-spinners';
import { AppTheme } from './theme';
import styled, { withTheme } from 'styled-components';
import EventsPage from './views/pages/events/EventsPage';
import DetailEventPage from './views/pages/detail_event/DetailEventPage';
import BandSettingsPage from './views/settings/bands/bandSettings/BandSettingsPage';
import EventFormPage from './views/pages/events/forms/EventFormPage';
import { EventController } from './controllers/eventController';
import { Band } from './models/band';
import { CustomerController } from './controllers/customerController';
import { FormationController } from './controllers/formationController';
import UserSettingsPage from './views/settings/userSettings/UserSettingsPage';
import Notifications from './views/pages/notifications/Notifications';
import { NotificationController } from './controllers/notificationController';
import { Collections } from './models/basics/Collections';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from './store/RootStore';
import { GighubEvent } from './models/gighubEvent';
import { Formation } from './models/formation';
import { GighubNotification } from './models/gighubNotification';
import { Customer } from './models/customer';
import { UsersBandsController } from './controllers/usersBandsController';
import { UsersBands } from './models/users-bands';
import { Model } from './models/model';
import { Store } from './store/Store';
import NoBandsPage from './views/pages/no_bands/NoBandsPage';
import { toJS } from 'mobx';
import ICal from "./views/settings/userSettings/ICal";
import CookieConsent from "react-cookie-consent";
import { BillingInfos } from "./models/billingInfos";
import { BillingInfosController } from "./controllers/billingInfosController";
import SuccessPage from './views/pages/success/SuccessPage';
import { collection, query, where, getDocs, doc, getDoc, onSnapshot, collection as col } from "firebase/firestore";
import { BandController } from "./controllers/bandController";
import { BandAddressController } from './controllers/bandAddressController';
import { BandAddress } from './models/bandAddress';
import { BookingStatusController } from './controllers/bookingStatusController';
import { BookingStatus } from './models/bookingStatus';
import { MemberRoles } from './models/basics/MemberRoles';

enum AuthState {
    AUTHENTICATED,
    ADDITIONAL_INFORMATION,
    ACTIVATION,
    NON_AUTHENTICATED,
    LOADING,
}

interface Props {
    theme: AppTheme;
}

interface FirebaseDocData {
    availabilities?: string[];
    [key: string]: any;
  };

const Container = styled.div`
  background: ${props => props.theme.colors.background};
  height: 100vh;
`;
const App: FC<Props> = observer(({ theme }) => {
    const {
        bandsStore,
        eventsStore,
        customersStore,
        bandAddressesStore,
        bookingStatiStore,
        notificationsStore,
        userStore,
        formationsStore,
        billingInfosStore
    } = useContext(
        RootStoreContext
    );


    function handleFetchError() {
        toast.error('Error while fetching bands, please reload and try again');
    }

    const [authState, setAuthState] = useState(AuthState.LOADING);
    const [hasBands, setHasBands] = useState(true);
    const url = window.location.pathname.split('/').pop();

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        bandsStore.setSelectedBandFromLocalStorage();
    }, [toJS(bandsStore.observables)]);

    useEffect(() => {
        const pathname = window.location.href;
        localStorage.setItem('pageUrl', JSON.stringify(pathname))
    }, []);

    async function loadData() {
        console.log("LOADING ALL DATA");
        // fix for material ui warning
        (window as any).__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
        auth.onAuthStateChanged(async user => {
            if (!user) {
                setAuthState(AuthState.NON_AUTHENTICATED);
            } else {
                // handle get user from db error?
                const authenticatedUser = await new UserController().get<User>(user.uid);
                if (!user.emailVerified) {
                    setAuthState(AuthState.ACTIVATION);
                } else if (!authenticatedUser) {
                    setAuthState(AuthState.ADDITIONAL_INFORMATION);
                } else {
                    // check if the user has any bands to avoid the flickering of the no band screen
                    const q = query(collection(database, Collections.USERSBANDS), where('idUser', '==', user.uid));
                    const res = await getDocs(q);
                    if (res.docs.length > 0) {
                        setHasBands(true);
                    } else {
                        setHasBands(false);
                    }
                    initializePush();
                    if (auth.currentUser) {
                        await onSnapshot(doc(database, Collections.USERS, auth.currentUser.uid), async (change) => {
                            const userBands = await new UsersBandsController().getByField<UsersBands>('idUser', change.id);
                            const currentUser1 = new User({
                                ...(change.data() as User),
                                id: change.id,
                            });

                            (window as any).gighub = {};
                            (window as any).gighub.CurrentUser = currentUser1;
                            (window as any).gighub.CurrentUser.fullName = currentUser1.surname + ' ' + currentUser1.name;
                            userStore.currentUser = currentUser1;
                            await fetchBands(currentUser1, userBands!).catch(handleFetchError);
                            bandsStore.setSelectedBandFromLocalStorage();
                            await fetch<GighubNotification>(notificationsStore).catch(handleFetchError);
                        });
                    }
                    setAuthState(AuthState.AUTHENTICATED);
                }
            }
        });
    }

    async function fetchBands(user: User, userBands: UsersBands[]) {
        let band: Band | null;
        try {
            if (user && userBands && userBands.length > 0) {
                const promises: Array<Promise<void>> = [];
                for (const userBand of userBands) {
                    if (userBand.idBand) {
                        await onSnapshot(doc(database, Collections.BANDS, userBand.idBand), async (change) => {
                            band = change.data() as Band;
                            if (band && change && change.id) {
                                band.id = change.id;
                                bandsStore.update(band);
                            }
                        });
                        band = await new BandController().get(userBand.idBand)
                        if (band && band.id && user.id) {
                            await fetchSubscriptionStatus(band)
                            await fetchMembers(band);
                            if (band.members[user.id] && band.members[user.id].role === MemberRoles.SUB) {
                                // Fetch events only if the current user's uid is in the availabilities
                                await fetch<GighubEvent>(
                                    eventsStore,
                                    new EventController(userBand.idBand),
                                    user,
                                    band.members[user.id].role === MemberRoles.SUB
                                );
                            } else {
                                await fetch<GighubEvent>(eventsStore, new EventController(userBand.idBand));
                            }
                            await fetch<Formation>(formationsStore, new FormationController(userBand.idBand));
                            await fetch<Customer>(customersStore, new CustomerController(userBand.idBand));
                            await fetch<BandAddress>(bandAddressesStore, new BandAddressController(userBand.idBand));
                            await fetch<BookingStatus>(bookingStatiStore, new BookingStatusController(userBand.idBand));
                            await fetch<BillingInfos>(billingInfosStore, new BillingInfosController(userBand.idBand));
                        }
                    }
                }
                await Promise.all(promises);
            }
        } catch (e) {
            console.log(e);
        }
    }


    async function fetchSubscriptionStatus(band: Band) {
        const res = await getDoc(doc(database, Collections.BANDS_SUBSCRIPTIONS, band.id))
        if (!res.exists) {
            band.subscriptionType = 'free';
            band.canceledSubscription = false;
            bandsStore.update(band);
        }
        await getDoc(doc(database, Collections.BANDS_SUBSCRIPTIONS, band.id))
        await onSnapshot(doc(database, Collections.BANDS_SUBSCRIPTIONS, band.id), async (change) => {
            if (change.data()) {
                band.subscriptionType = change.data()!.status;
                band.canceledSubscription = change.data()!.canceled;
                bandsStore.update(band);
            }
        });
    }

    async function fetchMembers(band: Band) {
        try {
            if (band.members) {
                await Promise.all(Object.keys(band.members).map(async idMember => {
                    const newMember = await new UserController().get<User>(idMember)
                    if (newMember && band.members) {
                        band.members[idMember].fullName = newMember.surname + ' ' + newMember.name;
                        band.members[idMember].imageUrl = newMember.imageUrl;
                        bandsStore.update(band);
                    }
                }));
            }
        } catch (e) {
            console.log(e);
        }
    }

    async function fetch<T extends Model>(
        store: Store<T>,
        controller?: EventController | CustomerController | FormationController | BillingInfosController,
        currentUser?: User,
        filterEventsByAvailability?: boolean
    ) {
        try {
            const responses = []
            if (controller) {
                responses[0] = (await controller.subscribe());
            } else {
                responses[0] = await new NotificationController().subscribeByAuthUser();
                responses[1] = await new NotificationController().subscribeByAuthUserEmail();
            }

            if (responses.length > 0) {
                await Promise.all(responses.map(async (response) => {
                    const q = query(response);
                    await onSnapshot(q, async (snapshot) => {
                        await Promise.all(snapshot.docChanges().map(async (change) => {
                            if (change.type === "removed") {
                                store.remove(change.doc.id);
                            } else {
                                const data = await change.doc.data() as FirebaseDocData;
                                if (filterEventsByAvailability && currentUser && data.availabilities && !((currentUser.id || "") in data.availabilities)) {
                                    return;
                                } else {
                                    store.update({ id: change.doc.id, ...data } as T);
                                }
                                
                            }
                        }))

                    });
                }
                ))
            }
        } catch (e) {
            console.log(e);
        }
    }

    function renderMainPage() {
        if (authState === AuthState.LOADING) {
            return (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '80vh' }}>
                    <RingLoader
                        size={30}
                        color={theme.colors.black}
                        loading={authState === AuthState.LOADING}
                    />
                </div>
            );
        } else if (authState === AuthState.AUTHENTICATED) {
            return (
                <>
                    <Router primary={false}>
                        <NoBandsPage default path={Routes.NOBANDSPAGE} />
                        <HubPage path={Routes.HOME} />
                        <SuccessPage path={Routes.SUCCESS} />
                        <Notifications
                            path={Routes.NOTIFICATIONS || Routes.ANSWER_BAND_INVITE || Routes.ANSWER_EVENT_INVITE} />
                        <CalendarPage path={Routes.CALENDAR} theme={theme} />
                        <Bands path={Routes.BANDS} />
                        <BandSettingsPage path={Routes.BAND_SETTINGS} />
                        <BandSettingsPage path={Routes.BAND_SETTINGS_WITH_TAB} />
                        <EventsPage path={Routes.EVENTS} />
                        <DetailEventPage path={Routes.EVENT} />
                        <EventFormPage path={Routes.EVENT_FORM_PAGE} />
                        {userStore.currentUser && <UserSettingsPage path={Routes.USER_SETTINGS} />}
                        {userStore.currentUser && <ICal path={Routes.ICAL} />}
                    </Router>
                    <CookieConsent>
                        This website uses cookies to enhance the user experience.
                    </CookieConsent>
                </>
            );
        } else if (authState === AuthState.ACTIVATION) {
            return (
                toast.info('Please validate your email! Also check your spam-folder',{
                    autoClose: 20000,  // Closes after 20 seconds 
                  }) && (
                    <Router>
                        <LoginPage path={Routes.HOME} />
                        <SignupPage path={Routes.SIGNUP} />
                        <ForgotPasswordPage path={Routes.FORGOT_PASSWORD} />
                    </Router>
                )
            );
        } else if (authState === AuthState.ADDITIONAL_INFORMATION) {
            return (
                <Router>
                    <AdditionalUserInformationPage path={Routes.HOME} />
                </Router>
            );
        } else {
            return (
                <Router>
                    <LoginPage path={Routes.HOME} />
                    <SignupPage path={Routes.SIGNUP} />
                    <ForgotPasswordPage path={Routes.FORGOT_PASSWORD} />
                </Router>
            );
        }
    }

    return (
        <Container>
            {renderMainPage()}
            <ToastContainer hideProgressBar={true} />
        </Container>
    );
});

export default withTheme(App);

import React, { FC, useContext } from 'react';
import { Formation as FormationModel } from "../../../../../models/formation";
import FormationForm from "./FormationForm";
import { Band } from "../../../../../models/band";
import Formation from './Formation';
import { useToggle } from 'src/hooks/useToggle';
import Fab from 'src/views/_common/_elements/Fab';
import styled from 'styled-components';
import { Dialog } from '@material-ui/core';
import { observer } from 'mobx-react-lite';
import { RootStoreContext } from 'src/store/RootStore';
import { MAX_FORMATIONS } from 'src/constants/FreePlan';
import PaymentDialog from 'src/views/_common/_elements/PaymentDialog';
import {BandController} from "../../../../../controllers/bandController";
import {toast} from "react-toastify";
import {FormationController} from "../../../../../controllers/formationController";



interface Props {
    band: Band;
    formations: FormationModel[];
    onNext?: () => void; 
}

const Container = styled.div`
      margin-top: 2rem;
`;

const FormationSettings: FC<Props> = observer(({ band, formations }) => {
    const { on: addFormationOpen, toggle: toggleAddFormation } = useToggle();
    const { on: paymentFormOpen, toggle: togglePaymentForm } = useToggle();
    const { userStore: { currentUser }, formationsStore: { getByBand } } = useContext(RootStoreContext);

    function handleAddFormationClick() {
        const formationCount = getByBand(band.id).get().length;
        if (band.subscriptionType === 'premium' || formationCount < MAX_FORMATIONS) {
            toggleAddFormation();
        } else {
            togglePaymentForm();
        }
    }

    async function handleDelete(formation: FormationModel) {
        if (band && formation && formation.id) {
            await new FormationController(band.id).delete(formation.id);
        } else {
            toast.error('Oops! Something went wrong');
        }
    }

    return (
        <Container>
            {formations.map(f => <Formation key={f.id} band={band} formation={f} handleDelete={handleDelete} />)}
            <Dialog open={addFormationOpen} onClose={toggleAddFormation}>
                <FormationForm toggle={toggleAddFormation} newFormation={true}
                    band={band} />
            </Dialog>
            <Fab type={'ADD'} onClick={handleAddFormationClick} />
            {currentUser && <PaymentDialog on={paymentFormOpen} toggle={togglePaymentForm} currentUser={currentUser} bandId={band.id} />}
        </Container>
    )
})



export default FormationSettings;
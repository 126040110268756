import {Band} from "../band";
import {auth} from "../../database/fbApp";
import {GighubEvent} from "../gighubEvent";
import {AvailabilityStati} from "./AvailabilityStati";

export enum MemberRoles {
    ADMIN = 'Admin',
    EDITOR = 'Editor',
    MUSICIAN = 'Musician',
    CREW = 'Crew',
    SUB = 'Sub',
    NOT_AUTHORIZED = 'NOT_AUTHORIZED',
}

export function isAdmin(authRole: MemberRoles) {
    return (authRole === MemberRoles.ADMIN)
}

export function canWrite(authRole: MemberRoles) {
    return (authRole === MemberRoles.EDITOR || authRole === MemberRoles.ADMIN)
}

export function canWriteBand(band: Band) {
    if (auth.currentUser && band.members[auth.currentUser.uid]) {
        return (band.members[auth.currentUser.uid].role === MemberRoles.EDITOR || band.members[auth.currentUser.uid].role === MemberRoles.ADMIN)
    } else {
        return false
    }
}

export function isAdminBand(band: Band) {
    if (auth.currentUser && band.members[auth.currentUser.uid]) {
        return (band.members[auth.currentUser.uid].role === MemberRoles.ADMIN)
    } else {
        return false
    }
}

export function isParticipating(e: GighubEvent) {
    if (auth.currentUser) {
        return e.availabilities && e.availabilities[auth.currentUser.uid] && (e.availabilities[auth.currentUser.uid].status === AvailabilityStati.ACCEPTED || e.availabilities[auth.currentUser.uid].status === AvailabilityStati.PENDING)
    } else {
        return false
    }
}


import React, {FC, useContext, useEffect} from 'react';
import styled from "styled-components";
import AvatarImage from "../../_common/_elements/AvatarImage";
import {SecondaryLabelButton} from "../../_common/_elements/LabelButton";
import {NotificationTypes} from "../../../models/basics/NotificationTypes";
import {GighubNotification} from "../../../models/gighubNotification";
import {GighubEvent} from "../../../models/gighubEvent";
import {observer} from 'mobx-react-lite';
import {useToggle} from 'src/hooks/useToggle';
import {RootStoreContext} from 'src/store/RootStore';
import EventInfoComponent from "./EventInfoContainer";
import {navigate} from "@reach/router";
import {Links} from "../../../constants/Routes";
import {Button, Dialog, DialogActions} from "@material-ui/core";
import { format, parseISO } from 'date-fns';

interface Props {
    notification: GighubNotification
    event: GighubEvent | null
    bandInviteModalOpen: boolean
    handleInviteAccept: (notification: GighubNotification) => any
    handleInviteDecline: (notification: GighubNotification) => any
    imageUrl: string
}


const Container = styled.div<{ seen: boolean }>`
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr auto;
  grid-column-gap: 7px;
  grid-row-gap: 3px;
  align-items: center;
  padding: .7rem 0;
  opacity: ${props => props.seen ? '0.6' : '1'};
  &:not(:last-of-type){
    margin-bottom: 1rem;
    border-bottom: 1px solid ${props => props.theme.colors.secondary};
  }
`;


const ReplyButton = styled(SecondaryLabelButton)`
  height: min-content;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 68%;
  justify-content: space-between;
`;


const NotificationComponent: FC<Props> = observer(props => {
    const {notification, handleInviteAccept, handleInviteDecline, event, imageUrl} = props;
    const {type, message, idReference, idBand, dateCreated} = notification;
    const {on, toggle} = useToggle();
    const {bandsStore} = useContext(RootStoreContext);
    const band = bandsStore.getById(idBand).get();

    return (
        <>
            <Container seen={notification.isSeen} onClick={() => {
                if (type === NotificationTypes.INVITEANSWER) {
                    navigate(Links.EVENT + idReference)
                }
            }}>
                <AvatarImage size="small" imgUrl={imageUrl} fallback={band ? band.shorthand : ''}/>
                <TitleContainer>
                    <p>{message}</p>
                    <small>{format(dateCreated instanceof Date ? dateCreated : parseISO(dateCreated), "dd.mm.yyyy - hh:mm")}</small>
                </TitleContainer>
                {(type === NotificationTypes.BANDINVITATION
                    || type === NotificationTypes.EVENTINVITATION) &&
                <ReplyButton onClick={toggle}>Reply</ReplyButton>
                }
            </Container>

            <Dialog open={on} onClose={toggle}>
                {(type === NotificationTypes.EVENTINVITATION) &&
                <EventInfoComponent idEvent={idReference} notification={true}/>
                }
                <DialogActions>
                    <Button color={"secondary"} onClick={() => {
                        toggle();
                        handleInviteDecline(notification);
                    }
                    }>decline
                    </Button>
                    <Button color={"primary"} onClick={() => {
                        toggle();
                        handleInviteAccept(notification);
                    }
                    }>accept
                    </Button>
                </DialogActions>
            </Dialog>
        </>

    )
});


export default NotificationComponent;